@import "styles/common";

.winners-section {
  .flex-row-center {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 80%;
    }
    @include media-breakpoint-up(lg) {
      width: 60%;
    }
  }

  .daily,
  .weekly {
    border-radius: 20px;
    @include media-breakpoint-up(md) {
      border-radius: 30px;
    }
    @include media-breakpoint-up(lg) {
      border-radius: 50px;
    }
  }

  .daily-winners {
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.8em;
    row-gap: 0.3em;
    padding: 0;
    margin: 0;

    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 1.2em;
      line-height: 1.7;
      border-radius: 30px;
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      border-radius: 50px;
    }
  }
}
