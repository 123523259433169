@import "styles/common";

.slogan-picture {
  max-width: 100%;
  @include media-breakpoint-up(lg) {
    max-width: 1700px;
  }
  width: $content-max-width;
}

.slogan-section > div a{
  max-width: 100%;
  width: 20em;
}
