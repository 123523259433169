@import "styles/common";

.header {
  .nav-item .nav-link {
    color: white;
    font-weight: bold;
  }

    .logo {
        width: 100%;
        min-width: 5em;
        max-width: 8em;
    }

  .navbar-brand {
    color: white;
  }


  .navbar-collapse {
    justify-content: flex-end;
  }

  .logo {
    width: 100%;
    min-width: 5em;
    max-width: 8em;
  }
}
