@import "styles/common";

.desc-section {

    .description-visual {
        display: grid;
        grid-template-rows: 1fr auto 1fr auto 1fr;
        grid-template-columns: auto;

        @include media-breakpoint-up(md) {
            grid-template-columns: 3fr 1fr 3fr 1fr 3fr;
            grid-template-rows: auto;
        }

        width: fit-content;
        max-width: $content-max-width;
        row-gap: 3em;

        text-align: center;

        * {
            align-self: center;
            justify-self: center;
        }

        h3 {
            // @extend .fontb-4;
            text-transform: uppercase;
        }


        @include media-breakpoint-up(md) {
            .visual-desc {
                grid-row: 2 / 2;
            }
            @for $i from 0 through 2 {
                .visual-desc:nth-child(#{3*$i+2}) {
                    grid-column: #{2*$i + 1} / #{2*$i + 1};
                }
            }
        }
    }

    .visual-picture {
        background-color: $primary-color;
        width: 9em;
        height: 9em;
        position: relative;
    }

    .visual-picture:after {
        content: "";
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        color: #3b2774;
        font-weight: bold;
        font-size: 1.5em;
        position: absolute;
        width: 2em;
        height: 2em;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        border: none;
        border-radius: 50%;
    }

    @for $i from 0 through 2 {
        .visual-picture:nth-child(#{3*$i+1}):after {
            content: "#{$i + 1}";
        }
    }

    @include media-breakpoint-up(md) {
        @for $i from 0 through 2 {
            .visual-picture:nth-child(#{3*$i+1}) {
                grid-column: #{2*$i + 1} / #{2*$i + 1};
            }
        }
    }

    .arrow {
        width: auto;
        max-width: 5em;
    }

}
