@import "styles/common";

.form {
  width: 100%;
  @include media-breakpoint-up(lg) {
    width: $content-max-width;
  }

  .textinput label {
    width: 100%;
  }

  label {
    p {
      margin: 0 0 0 1.8em;
      text-align: left;
      font-size: 0.84em;
    }
  }

  [type="text"], [type="number"], [type="email"], [type="tel"] {
    border: none;
    color: $secondary-color;
    &::placeholder {
      color: $secondary-color;
      opacity: 0.5;
    }
  }

  $validation-error-border-width: 3px;
  .validation-error input {
    border: solid $validation-error-border-width red !important;
    color: red;
  }

  .timepicker {
    display: grid;
    grid-template-columns: 1fr 1em 1fr;

    > p {
      font-size: inherit;
      align-self: end;
    }

    input {
      text-align: center;
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }


    * {
      align-self: center;
      justify-self: stretch;
    }
  }

  form {
    max-width: 100%;
    width: 100%;
    @include media-breakpoint-up(md) {
      width: $form-max-width;
    }
  }

  .ap:before {
    content: "AP";
    color: $secondary-color;
    position: absolute;
    font-weight: bold;
    left: 0.5rem;
    bottom: 0.5rem;

    @include media-breakpoint-up(lg) {
      left: 1rem;
      bottom: 1rem;
    }
  }

  $ap-input-padding-lg: 1rem;
  $ap-input-padding-xs: 0.5rem;
  .validation-error .ap:before {
    left:   calc(#{$ap-input-padding-xs} + #{$validation-error-border-width});
    bottom: calc(#{$ap-input-padding-xs} + #{$validation-error-border-width});

    @include media-breakpoint-up(lg) {
      left:   calc(#{$ap-input-padding-lg} + #{$validation-error-border-width});
      bottom: calc(#{$ap-input-padding-lg} + #{$validation-error-border-width});
    }
  }

  $ap-input-ap-text-length: 1.4em;
  $ap-input-letter-spacing: 1px;
  .ap input {
    padding-left: calc(#{$ap-input-padding-xs} + #{$ap-input-ap-text-length} + #{$ap-input-letter-spacing});
    @include media-breakpoint-up(lg) {
      padding-left: calc(#{$ap-input-padding-lg} + #{$ap-input-ap-text-length} + #{$ap-input-letter-spacing});
    }
  }

  .checkbox-container {
    input {
      appearance: none;
      width: 2em;
      height: 2em;
      position: relative;
      cursor: pointer;

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: solid 1px $secondary-color;
        border-radius: 50%;
      }

      &:before {
        content: "";
        position: absolute;
        width: 50%;
        height: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: none;
        border-radius: 50%;
        background-color: white;
      }

      &:hover:before {
        border: solid 2px $secondary-color;
      }

      &:checked:before {
        background-color: $secondary-color;
      }
    }

    label {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 0.5em;
    }

    p {
      margin: 0;
      font-weight: normal;
    }
  }
}
.validation-error.checkbox-container input:after {
  border-color: red;
}
