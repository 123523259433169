$secondary-color: #0336f6;
$secondary-color-focus: #4C71F7;
$primary-color: #eb76b8;
$primary-color-focus: #F78DC9;
$regular-font-stack: "Montserrat", sans-serif;
$background-color: #e4e5e5;
$content-max-width: 60em;
$form-max-width: 30em;

$secondary: $secondary-color;
$primary: $primary-color;
