@import "styles/common";

.hero-section {
    .button-container {
        width: 100%;
        @include media-breakpoint-up(md) {
            width: fit-content;
        }
    }
    img {
        max-width: 1200px;
        width: 100%;
    }
}
