@import "common";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/utilities/api";

@import "bootstrap/scss/navbar";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/transitions";

:root {
  --bs-font-sans-serif: $regular-font-stack;
}

html {
  overflow-x: hidden;
}

body {
  font-family: $regular-font-stack;
  color: $secondary-color;
  overflow-x: hidden;
  background-color: $background-color;
}

.text-hover-react:hover {
   opacity: 0.75;
}

.flex-row-center {
  align-items: center;
}

.fw-semibold {
  font-weight: 500;
}

.fs-8 {
  font-size: 0.8rem;
}

.fs-7 {
  font-size: 0.9rem;
}

.fontbu-6 {
  font-weight: bold;
  text-transform: uppercase;
  @include media-breakpoint-up(lg) {
    font-size: 1.2em;
  }
};

.fontb-2 {
  font-weight: bold;
  @include media-breakpoint-up(lg) {
    font-size: 2.4em;
  }
}

.fontb-4 {
    font-weight: bold;
    @include media-breakpoint-up(lg) {
      font-size: 1.55em;
    }
}

.legal {
    p, a {
    font-size: 0.85em;
    font-weight: normal;
  }
}

.list-style-type-none {
  list-style-type: none;
  padding: 0;
}

.empty-display-none:empty {
  display: none;
}

@mixin button-common-props () {
  transition-duration: 0.2s;
}

.button-secondary {
  @include button-common-props();

  background-color: $secondary-color;
  &:hover {
    background-color: $secondary-color-focus;
  }
}

.button-primary {
  @include button-common-props();

  background-color: $primary-color;
  &:hover {
    background-color: $primary-color-focus;
  }
}

.styled-select {
  appearance: none;
  background-image: url("../assets/select-arrow.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 1em) center;
  background-size: 1.4em;
  padding-right: 3em;
  border: none;
}
